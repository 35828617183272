import React, { useState, useEffect, FunctionComponent, useMemo, useRef, ReactElement, useContext } from 'react';
import GoogleMapReact, { } from 'google-map-react';
import './map.css';
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/map-marker';
import Geocode from 'react-geocode';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { font } from 'shared/utils/styles';
import Tooltip from '../Tooltip';
import useSupercluster from 'use-supercluster';
import { useHistory, useParams } from 'react-router-dom';

interface LocationPinProps {
  id: number;
  title: string;
  lat: number;
  lng: number;
}

interface ClusterPinProps {
  clusterPointCount: number;
  totalPointCount: number;
  onClick: () => void;
  lat: number;
  lng: number;
}

const Pin = styled.div`
  position: absolute;
  text-align: center;
  color: #3f51b5;
  font-size: 16;
  font-weight: bold;
  padding: 4;
  transform: translate(-50%, -100%);
  cursor: pointer;
`;

const CirclePin = styled.div<{ clusterPointCount: number; totalPointCount: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => 25 + (props.clusterPointCount / props.totalPointCount) * 100}px;
  height: ${props => 25 + (props.clusterPointCount / props.totalPointCount) * 100}px;
  background-color: ${({ theme }) => theme.danger};
  border-radius: 50%;
  color: #fff;
  ${font.size(15)};
`

export const LocationPin: FunctionComponent<LocationPinProps> = ({ title, id }) => {
  const themeContext = useContext(ThemeContext);
  const { orgName } = useParams<{ orgName: string }>();
  const history = useHistory();
  
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (e.type === 'contextmenu') {
      document.dispatchEvent(new MouseEvent('mouseup', {
        bubbles: true,
        cancelable: true,
        view: window
      }));
      
      window.open(`/${orgName}/deals/${id}`, '_blank');
    } else {
      history.push(`/${orgName}/deals/${id}`);
    }
  };

  return (
    <div 
      onClick={handleClick}
      onContextMenu={handleClick}
      style={{ userSelect: 'none' }}
    >
      <Tooltip content={title}>
        <Pin>
          <FontAwesomeIcon
            icon={['fas', 'map-marker-alt']}
            color={themeContext.danger}
            size={'3x'}
          />
        </Pin>
      </Tooltip>
    </div>
  );
};

export const ClusterPin: FunctionComponent<ClusterPinProps> = ({ clusterPointCount, totalPointCount, onClick }) => {
  return (
    <Tooltip content={`${clusterPointCount} Deals`}>
      <Pin onClick={onClick}>
        <CirclePin clusterPointCount={clusterPointCount} totalPointCount={totalPointCount}>{clusterPointCount}</CirclePin>
      </Pin>
    </Tooltip>
  );
};

interface MapProps {
  defaultZoom?: number;
  center?: GoogleMapReact.Coords;
  style?: React.CSSProperties;
  dealCoordinates: any;
}

type MapBounds = [number, number, number, number] | null;

const defaultCenter = { lat: 39.511556, lng: -96.612000 }
let count = 0;
const MapLegend = styled.div`
  position: absolute;
  bottom: 16px;
  left: 260px;
  background: ${props => props.theme.backgroundPrimary};
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 12px;
`;

const Map: FunctionComponent<MapProps> = ({ defaultZoom = 0, center = defaultCenter, style, dealCoordinates, }) => {
  const points = dealCoordinates?.map((pin: any) => ({
    type: "Feature",
    properties: { cluster: false, id: pin.id, title: pin.name },
    geometry: {
      type: "Point",
      coordinates: [
        pin.longitude,
        pin.latitude,
      ]
    }
  })) || [];

  const mapRef = useRef<any>();
  const [zoom, setZoom] = useState(defaultZoom);
  const [bounds, setBounds] = useState<MapBounds>(null);
  const [showTip, setShowTip] = useState(true);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  })

  useEffect(() => {
    const timer = setTimeout(() => setShowTip(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="map" style={style || { height: '100%', position: 'relative' }}>
      {showTip && dealCoordinates?.length > 0 && (
        <MapLegend>
          💡 Tip: Right-click any pin to open in a new tab
        </MapLegend>
      )}
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC1b-Gh-fK6fHfGgfWPbF1E3iXhxrbAR0M' }}
          center={center}
          defaultZoom={zoom}
          options={(maps) => ({
            mapTypeId: 'hybrid',
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: maps.MapTypeControlStyle.DROPDOWN_MENU,
            }
          })}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ])
          }}
        >
          {clusters?.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount, id, title } = cluster.properties;
            
            if (isCluster) {
              return (
                <ClusterPin
                  key={`cluster-${cluster.id}`}
                  clusterPointCount={pointCount}
                  totalPointCount={points.length}
                  lat={latitude}
                  lng={longitude}
                  onClick={() => {
                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                    if (mapRef.current) {
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }
                  }}
                />
              );
            }
            
            return (
              <LocationPin
                key={`location-${id}`}
                title={title}
                id={id}
                lat={latitude}
                lng={longitude}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;
